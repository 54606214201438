<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <CCol col="6" style="font-size: 25px">إنشاء فاتورة</CCol>
        </CCardHeader>
        <CCardBody>
          <div>
            <div class="row">
              <div class="col-md-12">
                <form @submit.prevent="CreateInvoice">
                  <label for="suppliers">
                    الموردين
                    <span class="star">*</span>
                  </label>
                  <multiselect
                    class="required"
                    v-model="selected_supplier"
                    :value="selected_supplier"
                    id="suppliers"
                    :options="suppliers"
                    :searchable="true"
                    selectedLabel=" العنصر المحدد"
                    deselectLabel="اضغط لإلغاء اختيار هذا العنصر"
                    selectLabel="اضغط لإختيار هذا العنصر"
                    :close-on-select="true"
                    placeholder="اختر ..."
                    label="name"
                    track-by="name"
                  >
                    <span slot="noOptions">اللائحة فارغة</span>
                    <span slot="noResult">لا توجد نتيجة</span>
                  </multiselect>
                  <div v-if(errors.supplier_id) id="validateSupplierId" class="invalid-feedback">
                    {{errors.supplier_id}}
                  </div>
                  <label for="categories"> المنتجات </label>
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <td width="15%">الاسم<span class="star">*</span></td>
                        <td width="20%">اللون<span class="star">*</span></td>
                        <td width="20%">الحجم<span class="star">*</span></td>
                        <td width="15%">الكمية</td>
                        <td width="15%">السعر</td>
                        <td width="10%">حذف</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-if="branches.length > 0"
                        v-for="(branch, index) in branches"
                        :key="index"
                      >
                        <td>
                          <multiselect
                            v-model="branch.product"
                            :value="branch.product"
                            :options="products"
                            :searchable="true"
                            selectLabel="اضغط لإختيار هذا العنصر"
                            :close-on-select="true"
                            selectedLabel=" العنصر المحدد"
                            deselectLabel="اضغط لإلغاء اختيار هذا العنصر"
                            class="required"
                            placeholder="اختر متنج ..."
                            label="name"
                            track-by="name"
                            @invalid="onInvalid"
                          >
                            <span slot="noOptions">لا توجد منتجات</span>
                          </multiselect>
                        </td>
                        <td>
                          <multiselect
                            v-model="branch.color"
                            :value="branch.color"
                            :options="colors"
                            :searchable="true"
                            selectLabel="اضغط لإختيار هذا العنصر"
                            :close-on-select="true"
                            selectedLabel=" العنصر المحدد"
                            deselectLabel="اضغط لإلغاء اختيار هذا العنصر"
                            class="required"
                            placeholder="اختر اللون ..."
                            label="name"
                            track-by="name"
                            @invalid="onInvalid"
                          >
                            <span slot="noOptions">لا توجد ألوان</span>
                          </multiselect>
                        </td>
                        <td>
                          <multiselect
                            v-model="branch.size"
                            :value="branch.size"
                            :options="sizes"
                            :searchable="true"
                            selectLabel="اضغط لإختيار هذا العنصر"
                            :close-on-select="true"
                            selectedLabel=" العنصر المحدد"
                            deselectLabel="اضغط لإلغاء اختيار هذا العنصر"
                            class="required"
                            placeholder="اختر المنطقة ..."
                            label="name"
                            track-by="name"
                            @invalid="onInvalid"
                          >
                            <span slot="noOptions">لا توجد أحجام</span>
                          </multiselect>
                        </td>
                        <td>
                          <input
                            type="number"
                            class="form-control"
                            v-model="branch.amount"
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            class="form-control"
                            v-model="branch.price"
                          />
                        </td>
                        <td>
                          <CButton
                            class="newItem"
                            type="reset"
                            size="sm"
                            color="danger"
                            @click="removeBranch"
                          >
                            حذف
                          </CButton>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div v-if(errors.products) id="validateProducts" class="invalid-feedback">
                    {{errors.products}}
                  </div>
                  <div class="btn">
                    <CButton
                      class="newItem"
                      size="lg"
                      color="primary"
                      @click="AddBranch"
                    >
                      إضافة منتج
                    </CButton>
                  </div>
                  <br />
                  <br />
                  <button type="submit" class="btn btn-primary">
                     حفظ 
                  </button>
                  &emsp;
                  <CButton color="warning" @click="goBack">رجوع</CButton>
                </form>
              </div>
            </div>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import "vue-select/dist/vue-select.css";
import swal from "sweetalert";
import $ from "jquery";
export default {
  name: "EditEntity",
  data() {
    return {
      invoice: [],
      suppliers: [],
      selected: "",
      selected_supplier: "",
      products: [],
      sizes: [],
      colors: [],
      branches: [],
      options: [],
      errors: [],
    };
  },
  created() {
    let http = this.$http;

    let colors = [];
    http.get(`${process.env.VUE_APP_URL}colors`).then((response) => {
      $.each(response.data.data, function (key, value) {
        colors.push({
          id: value.id,
          name: value.name,
        });
      });
      this.colors = colors;
    });

    let products = [];
    http.get(`${process.env.VUE_APP_URL}products`).then((response) => {
      $.each(response.data.data, function (key, value) {
        products.push({
          id: value.id,
          name: value.name,
        });
      });
      this.products = products;
    });

    let sizes = [];
    http.get(`${process.env.VUE_APP_URL}sizes`).then((response) => {
      $.each(response.data.data, function (key, value) {
        sizes.push({
          id: value.id,
          name: value.name,
        });
      });
      this.sizes = sizes;
    });

    let suppliers = [];
    this.$http.get(`${process.env.VUE_APP_URL}suppliers`).then((response) => {
      $.each(response.data.data, function (key, value) {
        suppliers.push({ id: value.id, name: value.name });
      });
      this.suppliers = suppliers;
    });
  },
  methods: {
    CreateInvoice(e) {
      this.errors = [];

      if (!this.selected_supplier.id) this.errors.push(`اسم المورد مطلوب`);
      if (!this.branches.length) this.errors.push(`عليك اضافة منتج واحد على الأقل`);
      console.log(this.errors);

      this.branches.map((branch, index) => {
        if (!branch.product.id)
          this.errors.push(`المنتج في المنتج ${index + 1} مطلوب`);
        if (!branch.color.id)
          this.errors.push(`اللون في المنتج ${index + 1} مطلوب`);
        if (!branch.size.id)
          this.errors.push(`الحجم في المنتج ${index + 1} مطلوب`);
        if (!branch.amount)
          this.errors.push(`الكمية في المنتج ${index + 1} مطلوب`);
        if (!branch.price)
          this.errors.push(`سعر الوحدة في المنتج ${index + 1} مطلوب`);
      });

      if (this.errors.length) {
        swal({
          title: this.errors[0],
          buttons: "تم",
        });
        e.preventDefault();
        return false;
      }
      let currentObj = this;
      let formData = new FormData();

      formData.append("supplier_id", this.selected_supplier.id);
      formData.append("products", JSON.stringify(this.branches));

      this.$http
        .post(`${process.env.VUE_APP_URL}invoices`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (!response.data.error) {
            swal({
              title: response.data.message,
              buttons: "تم",
            });
            currentObj.$router.push({ path: "/invoices" });
          } else {
            currentObj.errors = response.data.data;
          }
        });
    },
    AddBranch() {
      this.branches.push({
        product: { id: null, name: null },
        size: { id: null, name: null },
        color: { id: null, name: null },
        name: "",
        amount: 0,
        price: 0,
      });

      this.options.push({
        products: [],
        colors: [],
        sizes: [],
      });
    },
    removeBranch() {
      this.branches.splice(this.branches, 1);
    },
    AddMenuItem() {
      this.menu_items.push({
        category_id: 0,
        name: "",
        price: "",
        branches: [],
      });
    },
    removeMenuItem() {
      this.menu_items.splice(this.menu_items, 1);
    },
    onInvalid() {
      var element = $(this).closest(".ui-tabs-panel").index();
      $("#tabs").tabs().tabs("option", "active", element);
    },
    uploadImageSuccess(formData, index, fileList) {
      let image_index = index + JSON.parse(this.invoice.extra_image).length + 2;
      console.log(this.invoice, "image_" + image_index);
      var file = dataURLtoFile(fileList[index].path, "image_" + image_index);
      this.invoice["image_" + image_index] = file;
      this["image_" + image_index] = file;
      console.log(this["image_" + image_index]);
    },
    beforeRemove(index, done, fileList) {
      console.log("index", index, fileList);
      var r = confirm("remove image");
      if (r == true) {
        done();
      }
    },
    editImage(formData, index, fileList) {
      console.log("edit data", formData, index, fileList);
    },
    goBack() {
      this.invoicesOpened
        ? this.$router.go(-1)
        : this.$router.push({
            path: "/invoices",
          });
    },
    checkForm: function (e) {
      if (this.image) {
        return true;
      }

      this.errors = [];

      if (!this.image) {
        swal({
          title: "الصورة مطلوب",
          buttons: "تم",
        });
      }

      e.preventDefault();
    },
  },
};

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, {
    type: mime,
  });
}
</script>

<style>
#my-strictly-unique-vue-upload-multiple-image {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  width: 100px !important;
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
